import React, { useEffect, useState } from 'react';
import Globe from 'react-globe.gl';
import countries from './countries.json';
import useWindowDimensions from './hooks/windowDimension';

interface OverlayData {
  text: string;
  color: string;
  color_active: string;
}

const App: React.FC = () => {
  const [hoverD, setHoverD] = useState<any>();

  const {width, height} = useWindowDimensions();

  const [overlay, setOverlay] = useState<Record<string, OverlayData>>();

  useEffect(() => {
    fetch('map_overlay.json').then(res => res.json()).then(data => {
      setOverlay(data);
    });
  }, []);

  const getColor = (feat: any) => {

    const data = overlay?.[feat.properties.ADM0_A3];
    if (data) 
      return feat === hoverD ? data.color_active : data.color;

    return "#77777788";
  };

  const getHeight = (feat: any) => {
    const elevated = overlay &&  Object.keys(overlay).indexOf(feat.properties.ADM0_A3) > -1;
    return feat === hoverD && elevated ? 0.04 : 0.006;
  }

  return (
    <div className="App">
      <Globe
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
      lineHoverPrecision={0}

      polygonsData={countries.features}
      polygonAltitude={d => getHeight(d)}
      polygonCapColor={d => getColor(d)}
      polygonSideColor={() => 'rgba(0, 0, 0, 0.5)'}
      polygonStrokeColor={() => '#111'}
      polygonLabel={({ properties: d }: any) => {
        if (overlay && overlay[d.ADM0_A3])
          return overlay[d.ADM0_A3].text;

        return `<b>${d.ADMIN}</b>`;
        }
      }
      onPolygonHover={(elem) => {if (elem) setHoverD(elem);}}
      polygonsTransitionDuration={300}
      width={width}
      height={height}
    />
    </div>
  );
}

export default App;
